var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "ml-0 ml-md-6 py-6 px-4 px-md-16 rounded-lg full-width",
      attrs: { color: "light_gray", tile: "", elevation: "0" },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12", lg: "10" } },
            [
              _c(
                "v-form",
                {
                  ref: "deleteAccountForm",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitDeleteAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "gray--text font-weight-bold text-body-1 text-capitalize",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("label.deleteAccountHeadline"))
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [_c("v-divider")],
                        1
                      ),
                      [
                        _c(
                          "v-row",
                          { staticClass: "pl-md-2 px-2" },
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c("div", {
                                staticClass:
                                  "text-body-2 unbox_secondary--text",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("label.deleteAccountMessage")
                                  ),
                                },
                              }),
                            ]),
                            _c(
                              "v-col",
                              { staticClass: "py-1", attrs: { cols: "12" } },
                              [
                                _c("app-form-field", {
                                  attrs: {
                                    type: "password",
                                    label: _vm.$t("label.password"),
                                    placeholder: _vm.$t("label.retypePassword"),
                                    rules: _vm.$validation.passwordRules(),
                                  },
                                  model: {
                                    value: _vm.deleteAccountForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.deleteAccountForm,
                                        "password",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "deleteAccountForm.password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                                      attrs: {
                                        type: "submit",
                                        height: "56px",
                                        "max-width": "300px",
                                        color: "red",
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("label.deleteAccount")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }